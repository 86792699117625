import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index1111"} />
		<Helmet>
			<title>
				Разработка крутых приложений | Кодовое слово  
			</title>
			<meta name={"description"} content={"Приложения, сайты, чат-боты, автоматизация бизнеса"} />
			<meta property={"og:title"} content={"Наши проекты | Кодовое слово  "} />
			<meta property={"og:description"} content={"Приложения, сайты, чат-боты, автоматизация бизнеса"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/favicon.ico?v=2021-08-10T08:08:05.993Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/60feb3ae63f5070017af70c6/images/code_word_logo-02-01.png?v=2021-08-10T08:08:05.992Z"} />
		</Helmet>
		<Box
			overflow-x="visible"
			max-height="100vh"
			flex-direction="column"
			height="100vh"
			width="100%"
			quarkly-title="chat"
			min-height="100vh"
			display="flex"
			overflow-y="scroll"
			sm-height="100vh"
			sm-min-height="100vh"
			sm-max-height="100vh"
			sm-padding="0 8px 0 8px"
			padding="16px 8px 8px 8px"
			align-items="center"
			justify-content="flex-end"
		>
			<Box
				min-height="max-content"
				width="100%"
				height="max-content"
				display="flex"
				padding="20px 8px 8px 8px"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				quarkly-title="cont-1"
				max-width="1200px"
				flex-direction="column"
				sm-min-height="max-content"
				border-radius="8px"
				box-shadow="0 1px 3px 3px rgba(23, 23, 29, 0.1)"
			>
				<Box
					min-width="100px"
					min-height="40px"
					width="100%"
					display="flex"
					height="40px"
					sm-height="40px"
					sm-min-height="40px"
					sm-margin="0 0 8px 0"
					quarkly-title="header"
					margin="0 0 8px 0"
				>
					<Image
						src="https://images.unsplash.com/photo-1454496522488-7a8e488e8606?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=200"
						display="block"
						width="40px"
						height="40px"
						min-width="40px"
						min-height="40px"
						margin="0 8px 0 0"
						border-radius="50%"
						object-fit="cover"
						sm-width="40px"
						sm-height="40px"
						sm-min-width="40px"
						sm-min-height="40px"
						quarkly-title="Photo"
					/>
					<Box
						min-width="100px"
						min-height="40px"
						width="100%"
						height="40px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						justify-content="center"
						sm-height="40px"
						sm-min-height="40px"
						quarkly-title="metaBox"
					>
						<Text margin="0px 0px 0px 0px" quarkly-title="Name" color="#17171d">
							Some text
						</Text>
						<Text margin="0px 0px 0px 0px" quarkly-title="date" color="#17171d">
							Some text
						</Text>
					</Box>
				</Box>
				<Text
					margin="0px 0px 0px 0px"
					sm-height="max-content"
					height="max-content"
					min-height="max-contentm"
					color="#17171d"
					quarkly-title="message"
				>
					Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text
				</Text>
			</Box>
			<Box
				min-height="max-content"
				width="100%"
				height="max-content"
				display="flex"
				padding="20px 8px 8px 8px"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				quarkly-title="cont-2"
				max-width="1200px"
				flex-direction="column"
				sm-min-height="max-content"
				border-radius="8px"
				box-shadow="0 1px 3px 3px rgba(23, 23, 29, 0.1)"
				background="#b6d4d7"
			>
				<Box
					min-width="100px"
					min-height="40px"
					width="100%"
					display="flex"
					height="40px"
					sm-height="40px"
					sm-min-height="40px"
					sm-margin="0 0 8px 0"
					quarkly-title="header"
					margin="0 0 8px 0"
				>
					<Image
						src="https://images.unsplash.com/photo-1454496522488-7a8e488e8606?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=200"
						display="block"
						width="40px"
						height="40px"
						min-width="40px"
						min-height="40px"
						margin="0 8px 0 0"
						border-radius="50%"
						object-fit="cover"
						sm-width="40px"
						sm-height="40px"
						sm-min-width="40px"
						sm-min-height="40px"
						quarkly-title="Photo"
					/>
					<Box
						min-width="100px"
						min-height="40px"
						width="100%"
						height="40px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						justify-content="center"
						sm-height="40px"
						sm-min-height="40px"
						quarkly-title="metaBox"
					>
						<Text margin="0px 0px 0px 0px" quarkly-title="Name" color="#17171d">
							Some text
						</Text>
						<Text margin="0px 0px 0px 0px" quarkly-title="date" color="#17171d">
							Some text
						</Text>
					</Box>
				</Box>
				<Text
					margin="0px 0px 0px 0px"
					sm-height="max-content"
					height="max-content"
					min-height="max-contentm"
					color="#17171d"
					quarkly-title="message"
				>
					Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text Some text
				</Text>
			</Box>
			<Box
				min-height="40px"
				quarkly-title="body"
				display="flex"
				width="100%"
				max-width="1200px"
				height="40px"
			>
				<Input
					display="block"
					placeholder-color="LightGray"
					background="#e9e9ef"
					height="40px"
					width="100%"
					padding="0 16px 0 16px"
					border-radius="8px"
					border-width="0px"
				/>
				<Button
					margin="0px 0px 0px 8px"
					height="40px"
					width="40px"
					min-width="40px"
					min-height="40px"
					padding="8px 15px 8px 15px"
					border-radius="50%"
					background="#18191f"
					color="#ffffff"
				>
					+
				</Button>
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60feb3ae63f5070017af70c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfBody"} rawKey={"619b78f310dffeaeba606725"} />
			<style place={"endOfBody"} rawKey={"619b7917f5e4ef1492682e06"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
			<script place={"endOfBody"} rawKey={"620d0b13860a3cd523791a26"}>
				{"<!-- Yandex.Metrika counter -->\n<script type=\"text/javascript\" >\n   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(87531028, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true,\n        webvisor:true\n   });\n</script>\n<noscript><div><img src=\"https://mc.yandex.ru/watch/87531028\" style=\"position:absolute; left:-9999px;\" alt=\"\" /></div></noscript>\n<!-- /Yandex.Metrika counter -->"}
			</script>
		</RawHtml>
	</Theme>;
});